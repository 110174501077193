import cn from 'classnames';

import {twMerge} from '@/stylesheets/twMerge';
import SectionHeader, {
  type SectionHeaderProps,
} from '@/components/brochureV2/section/SectionHeader';
import {ColorSchemeEnum} from '@/enums';

interface PosHeaderProps extends SectionHeaderProps {
  kickerClassName?: string;
  id?: string;
}

export default function PosHeader({
  id,
  kickerClassName,
  ...props
}: PosHeaderProps) {
  const kickerTextClass = cn({
    'text-lime-50': props.colorScheme === ColorSchemeEnum.PosDark,
    'text-lime-70': props.colorScheme !== ColorSchemeEnum.PosDark,
  });

  const subheadingTextClass = cn({
    'text-shade-20': props.colorScheme === ColorSchemeEnum.PosDark,
    'text-shade-70': props.colorScheme !== ColorSchemeEnum.PosDark,
  });

  return (
    <SectionHeader
      {...props}
      id={id}
      eyebrowClass={twMerge(kickerTextClass, kickerClassName)}
      headingWrapperClass={props.headingWrapperClass || 'max-w-4xl'}
      subheadingWrapperClass={twMerge(
        'max-w-3xl',
        subheadingTextClass,
        props.subheadingWrapperClass,
      )}
    />
  );
}
